import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../card.css";
import LocationCard from "./LocationCard";
// import {locations, output} from '../../data/LocationDetails';
import { locations, output } from "../../data/locations";
import Select from "react-select";
import { usePosition } from "../../utils/usePosition";

const LocationCardSection = () => {
  const [selectedCities, setSelectedCities] = useState([
    { value: "Los Angeles, CA", label: "Los Angeles, CA" },
  ]);
  const { latitude, longitude, error } = usePosition();

const [stores, setStores] = useState(locations)

useEffect(() => {
  if(latitude && longitude && locations.length ){
    let temp = locations.map(store => { 
        let xs = latitude - store.lat
        let ys = longitude - store.lng		
        store.distance = Math.sqrt( (xs*xs) + (ys*ys) );
        return store
    })
    setStores(temp)
    // setStores(locations)
  }
  else setStores(locations)
}, [locations, latitude, longitude])


  function handleChange(selectedCities) {
    setSelectedCities(selectedCities);
  }
  const { selectedOption } = selectedCities;

  return (
    <>
      {/* <CountDisplay>Viewing {output.length} of {output.length} locations</CountDisplay> */}
      <Label>Find locations in your city:</Label>
      <Select
        defaultValue={output[2]}
        isMulti
        options={output}
        value={selectedOption}
        placeholder="Select a city"
        searchable={true}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        styles={customStyles}
      />
      <h6>{`Your location: ${latitude}, ${longitude}`}</h6>
      <Container>
        {/* {locations.filter(locationInfo => selectedCities.some(cityObj => cityObj.value.includes(locationInfo.city))).map((locationInfo) => */}
        {latitude && longitude && stores
            .sort((a, b) => {
              return a.distance - b.distance;
            })
            .map((locationInfo) => (
              <LocationCard key={locationInfo.place_id} info={locationInfo}/>
            ))}
      </Container>
    </>
  );
};

export default LocationCardSection;

const Container = styled.div`
  margin: 50px 50px 50px 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CountDisplay = styled.h2`
  font-family: "Karla";
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #4d4d4f;
  letter-spacing: 0px;
  margin-top: 50px;
`;

const Label = styled.h2`
  font-family: "Karla";
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #4d4d4f;
  letter-spacing: 0px;
  margin-top: 50px;
`;

const customStyles = {
  control: (base) => ({
    ...base,
    fontFamily: "Karla",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "Karla",
  }),
};
