export const locations = [
  {
    "city": "Carson, CA",
    "name": "99 Bargain",
    "location": "21919 S Avalon Blvd, 90745",
    "hours": "9am - 9pm",
    "storeLink": "https://www.yelp.com/biz/99-cents-bargain-carson",
    "place_id": "EioyMTkxOSBTIEF2YWxvbiBCbHZkLCBDYXJzb24sIENBIDkwNzQ1LCBVU0EiMhIwChQKEgmdObmZGjXdgBFyypnEUnxwNxCfqwEqFAoSCfXNVYewNd2AET2ObyfMirPX",
    "lat": 33.82836770000001,
    "lng": -118.2639286
  },
  {
    "city": "Vernon, CA",
    "name": "Best Deal Closeout Inc.",
    "location": "4412 Pacific Blvd, 90058",
    "hours": "8:30am - 5:30pm",
    "storeLink": "https://www.google.com/search?q=best+deal+closeout+inc+4412+Pacific+Blvd%2C+90058",
    "place_id": "ChIJ4zjbxOnIwoARkU2l8dSqyEE",
    "lat": 34.0030006,
    "lng": -118.2300668
  },
  {
    "city": "Los Angeles, CA",
    "name": "Best Bargain",
    "location": "13010 S Avalon Blvd, 90061",
    "hours": "9am - 8:20pm",
    "storeLink": "https://www.loc8nearme.com/california/los-angeles/best-bargain/5484135/",
    "place_id": "ChIJE-jHq0jKwoARKchv_xtc124",
    "lat": 33.91443609999999,
    "lng": -118.2641252
  },
  {
    "city": "Anaheim, CA",
    "name": "Best Bargain",
    "location": "222 S Euclid St, 92802",
    "hours": "(714) 687-0860",
    "storeLink": "https://www.yelp.com/biz/best-bargain-anaheim",
    "place_id": "ChIJFw0Ck8Mp3YARpHvFwkrn4Rc",
    "lat": 33.8304809,
    "lng": -117.9409186
  },
  {
    "city": "Inglewood, CA",
    "name": "Best Bargain",
    "location": "209 W Arbor Vitae St, 90301",
    "hours": "7am - 10pm",
    "storeLink": "https://www.yelp.com/biz/best-bargain-inglewood",
    "place_id": "ChIJacfqUeO2woARMtif1v7RC24",
    "lat": 33.9531176,
    "lng": -118.358575
  },
  {
    "city": "Los Angeles, CA",
    "name": "Best Bargain",
    "location": "4163 S Broadway, 90037",
    "hours": "(323) 231-3166",
    "storeLink": "https://www.yelp.com/biz/best-bargain-los-angeles",
    "place_id": "ChIJOUtdfg3IwoARuOfKpcJt_4o",
    "lat": 34.0080604,
    "lng": -118.2786995
  },
  {
    "city": "Whittier, CA",
    "name": "Best Bargain",
    "location": "13238 Whittier Blvd, 90602",
    "hours": "9am - 9pm",
    "storeLink": "https://www.yelp.com/biz/best-bargain-whittier",
    "place_id": "ChIJD8UBTZrTwoARAVdedcyWris",
    "lat": 33.9636164,
    "lng": -118.0341153
  },
  {
    "city": "South Gate, CA",
    "name": "Big Outlet",
    "location": "8472 Otis St, 90280",
    "hours": "9am - 5pm",
    "storeLink": "https://www.facebook.com/bigoutletsouthgate1/",
    "place_id": "ChIJYQlAH6jOwoARs0_1_tAWemU",
    "lat": 33.9571696,
    "lng": -118.1955734
  },
  {
    "city": "Los Angeles, CA",
    "name": "Brandon’s Bargain",
    "location": "3722 Whittier Blvd, 90023",
    "hours": "9am - 6pm",
    "storeLink": "https://www.google.com/search?q=brandons+bargain+3722+Whittier+Blvd",
    "place_id": "ChIJFWB9CUbPwoAR7ds60SIAmRs",
    "lat": 34.02412169999999,
    "lng": -118.1930539
  },
  {
    "city": "Los Angeles, CA",
    "name": "Brandon’s Bargain",
    "location": "1214 S Lorena St, 90023",
    "hours": "9am - 6pm",
    "storeLink": "https://www.google.com/search?q=1214+S+Lorena+St",
    "place_id": "ChIJ0YY-WUvPwoAR8MHMYDwW-lc",
    "lat": 34.0210503,
    "lng": -118.2049471
  },
  {
    "city": "Maywood, CA",
    "name": "Dollar Box",
    "location": "4101 Slauson Ave #9, 90270",
    "hours": "6am - 11pm",
    "storeLink": "https://www.google.com/search?q=4101+Slauson+Ave",
    "place_id": "Eis0MTAxIFNsYXVzb24gQXZlICM5LCBNYXl3b29kLCBDQSA5MDI3MCwgVVNBIh0aGwoWChQKEgmT2dMvxc7CgBG3OQWKppUacxIBOQ",
    "lat": 33.9880516,
    "lng": -118.1919706
  },
  {
    "city": "Palmdale, CA",
    "name": "Dollar House",
    "location": "1813 E Palmdale Blvd H, 93550",
    "hours": "9am - 9pm",
    "storeLink": "https://www.yelp.com/biz/dollar-house-palmdale",
    "place_id": "ChIJQZchUZFXwoARcebP7HAVV5c",
    "lat": 34.5806721,
    "lng": -118.095241
  },
  {
    "city": "Carson, CA",
    "name": "Dollar USA Discount",
    "location": "23505 S Avalon Blvd, 90745",
    "hours": "9am - 8pm",
    "storeLink": "https://www.yelp.com/biz/dollar-usa-discount-inc-carson",
    "place_id": "EioyMzUwNSBTIEF2YWxvbiBCbHZkLCBDYXJzb24sIENBIDkwNzQ1LCBVU0EiMhIwChQKEglTbaWoqDXdgBHDiZQLoX2pcRDRtwEqFAoSCfXNVYewNd2AET2ObyfMirPX",
    "lat": 33.8118392,
    "lng": -118.2642004
  },
  {
    "city": "Montebello, CA",
    "name": "Dollar USA Discount",
    "location": "1131 W Whittier Blvd, 90640",
    "hours": "9am - 9pm",
    "storeLink": "https://www.loc8nearme.com/california/montebello/dollar-usa-discount-inc/5090739/",
    "place_id": "ChIJi1w4ISjOwoARi4226Bc3SoY",
    "lat": 34.0098185,
    "lng": -118.1125353
  },
  {
    "city": "Los Angeles, CA",
    "name": "First Bargain",
    "location": "3300 W 8th St, 90005",
    "hours": "9am - 8pm",
    "storeLink": "https://www.yelp.com/biz/first-bargain-los-angeles",
    "place_id": "ChIJn2f_woG4woARXHuxv7_udRU",
    "lat": 34.0575057,
    "lng": -118.30012
  },
  {
    "city": "North Hollywood, CA",
    "name": "First Bargain",
    "location": "8055 Webb Ave, 91605",
    "hours": "8:30am - 9pm",
    "storeLink": "https://www.yelp.com/biz/first-bargain-los-angeles-4",
    "place_id": "ChIJCbf2Eh-UwoARjjG9k_lmYHo",
    "lat": 34.2169755,
    "lng": -118.3895681
  },
  {
    "city": "Pacoima, CA",
    "name": "First Bargain",
    "location": "12729 Van Nuys Blvd, 91331",
    "hours": "8am - 10pm",
    "storeLink": "https://www.yelp.com/biz/first-bargain-pacoima",
    "place_id": "ChIJ4VRznZ6RwoARNU2CIb2O3VQ",
    "lat": 34.2749395,
    "lng": -118.4106054
  },
  {
    "city": "Corona, CA",
    "name": "Giant Dollar",
    "location": "1540 W 6th St #101, 92882",
    "hours": "8:30am - 9pm",
    "storeLink": "https://www.yelp.com/biz/giant-dollar-107-corona",
    "place_id": "ChIJBxTMbGDI3IARf9QM7BtdT9s",
    "lat": 33.8770065,
    "lng": -117.5950546
  },
  {
    "city": "Los Angeles, CA",
    "name": "JC Sales",
    "location": "2600 S Soto St, 90058",
    "hours": "M-F 6am - 3pm, Sat 6am-12pm, Sunday CLOSED",
    "storeLink": "https://www.jcsalesweb.com/",
    "place_id": "ChIJMTpNmXXJwoARxtTwrXx_qRY",
    "lat": 34.0132349,
    "lng": -118.2193618
  },
  {
    "city": "Monterey Park, CA",
    "name": "Q Bargain",
    "location": "Prado Center: 2203 S Atlantic Blvd, 91754",
    "hours": "9am - 8:45pm",
    "storeLink": "https://www.yelp.com/biz/q-bargain-monterey-park",
    "place_id": "ChIJK6RE95DPwoARvNEbgtFWyOQ",
    "lat": 34.03889069999999,
    "lng": -118.1451874
  },
  {
    "city": "Huntington Park, CA",
    "name": "Q Bargain",
    "location": "2911 E Florence Ave, 90255",
    "hours": "9am - 8:45pm",
    "storeLink": "https://www.google.com/search?q=Q+bargain+2911+E+Florence+Ave",
    "place_id": "ChIJu3jimjfJwoARR0qJfywMiTE",
    "lat": 33.9742328,
    "lng": -118.2168769
  },
  {
    "city": "El Monte, CA",
    "name": "Q Bargain",
    "location": "11911 Valley Blvd., 91732",
    "hours": "9am - 8:45pm",
    "storeLink": "https://www.google.com/search?q=11911+Valley+Blvd.%2C+91732",
    "place_id": "ChIJJSbsPLXQwoARsmw_yBV-cQU",
    "lat": 34.0637695,
    "lng": -118.0198438
  },
  {
    "city": "Los Angeles, CA",
    "name": "Super Bargain #3",
    "location": "1234 S Lorena St, 90023",
    "hours": "M-Sat 9am - 9pm, Sun 10am - 8pm",
    "storeLink": "https://www.yelp.com/biz/super-bargain-number-three-los-angeles",
    "place_id": "ChIJdWD4T0vPwoAR_FjMVkAvEbY",
    "lat": 34.0206137,
    "lng": -118.2052988
  },
  {
    "city": "Compton, CA",
    "name": "Super Bargain #7",
    "location": "261 W Compton Blvd, 90220",
    "hours": "M-Sat 9am - 9pm, Sun 10am-8pm",
    "storeLink": "https://www.yelp.com/biz/super-bargain-compton",
    "place_id": "ChIJcRaxoG3LwoARJQDL7UAbq_Q",
    "lat": 33.8963632,
    "lng": -118.2262672
  },
  {
    "city": "Compton, CA",
    "name": "Super Bargain #9",
    "location": "1208 E Compton Blvd, 90221",
    "hours": "M-Sat 9am - 9pm, Sun 10am-8pm",
    "storeLink": "https://www.facebook.com/Super-Bargain-9-1609272472452115/",
    "place_id": "ChIJlToGa2HLwoARFOpxHQoxnkw",
    "lat": 33.895702,
    "lng": -118.2100579
  },
  {
    "city": "Paramount, CA",
    "name": "Unique Bargain",
    "location": "7922 Rosecrans Ave Ste Q, 90723",
    "hours": "8am - 8pm",
    "storeLink": "https://www.google.com/search?q=7922+Rosecrans+Ave",
    "place_id": "Ei43OTIyIFJvc2VjcmFucyBBdmUgcSwgUGFyYW1vdW50LCBDQSA5MDcyMywgVVNBIh0aGwoWChQKEgmFeP3N38zCgBE4T0emX74GJRIBcQ",
    "lat": 33.9029028,
    "lng": -118.1612269
  },
  {
    "city": "Huntington Park, CA",
    "name": "Dollar Happy",
    "location": "2726 E Gage Ave, 90225",
    "hours": "8:15am - 9pm, Sunday CLOSED",
    "storeLink": "http://www.yelp.com/biz/dollar-max-98-cents-huntington-park",
    "place_id": "ChIJQYq3yyTJwoARL984BysBBQk",
    "lat": 33.9813596,
    "lng": -118.2217323
  },
  {
    "city": "Long Beach, CA",
    "name": "Dollar Zone",
    "location": "5685 Paramount Blvd, 90805",
    "hours": "8:30am - 8pm",
    "storeLink": "http://www.yelp.com/biz/dollar-zone-long-beach",
    "place_id": "ChIJP3ZuPx4z3YARo5gc-eeIDC4",
    "lat": 33.8592796,
    "lng": -118.1600747
  },
  {
    "city": "Los Angeles, CA",
    "name": "Dollar World",
    "location": "3030 N. Broadway, 90031",
    "hours": "M-F 9am - 5pm, Sunday CLOSED",
    "storeLink": "http://www.yelp.com/biz/dollar-world-los-angeles-2",
    "place_id": "ChIJp7gH-37GwoARJC9zcsTQ60E",
    "lat": 34.0734299,
    "lng": -118.2093039
  },
  {
    "city": "Compton, CA",
    "name": "Ekono",
    "location": "1940 N. Bulls Rd. #A, 90221",
    "hours": "8am - 8pm",
    "storeLink": "http://www.yelp.com/biz/ekono-99-compton",
    "place_id": "EioxOTQwIE4gQnVsbGlzIFJkIGEsIENvbXB0b24sIENBIDkwMjIxLCBVU0EiHRobChYKFAoSCT2riA9-y8KAEYeY2DDbOK32EgFh",
    "lat": 33.9085189,
    "lng": -118.2067164
  },
  {
    "city": "Whittier, CA",
    "name": "Dollar Super Store",
    "location": "7850 Norwalk Blvd, 90606",
    "hours": "M-Sat 8:30am - 8:30pm, Sun 9am-8pm",
    "storeLink": "http://www.yelp.com/biz/dollar-super-store-whittier",
    "place_id": "ChIJJwqMCTHSwoARl8fhdroNTz8",
    "lat": 33.9740705,
    "lng": -118.0729901
  },
  {
    "city": "Santa Ana, CA",
    "name": "Dollar Zone",
    "location": "1005 Main St., 92701",
    "hours": "M-Sat 9am - 8pm, Sun 9am - 7pm",
    "storeLink": "http://www.loc8nearme.com/california/santa-ana/dollar-zone/5488294/",
    "place_id": "ChIJRYWdFBrZ3IAROieYwoC_y2o",
    "lat": 33.7355601,
    "lng": -117.8674627
  },
  {
    "city": "Arleta, CA",
    "name": "Power Dollar",
    "location": "9077 Woodman Ave, 91331",
    "hours": "(818) 891-9799",
    "storeLink": "http://www.yelp.com/biz/power-dollar-arleta",
    "place_id": "ChIJf33ictiQwoARw8sk0uv0nD8",
    "lat": 34.2346583,
    "lng": -118.4403817
  },
  {
    "city": "Panorama City, CA",
    "name": "Power Dollar",
    "location": "8226 Van Nuys Blvd., 91402",
    "hours": "9am - 9pm",
    "storeLink": "https://www.yelp.com/biz/power-dollar-van-nuys",
    "place_id": "ChIJBQQyKC2XwoARXyV9gaBOv6o",
    "lat": 34.2207355,
    "lng": -118.4484839
  },
  {
    "city": "Hawthorne, CA",
    "name": "Quality Bargain",
    "location": "3904 W Rosecrans Ave, 90250",
    "hours": "9am - 8:30pm",
    "storeLink": "http://www.yelp.com/biz/quality-bargain-hawthorne",
    "place_id": "ChIJEzfvR8q1woARs4RcRH0nWmM",
    "lat": 33.90175619999999,
    "lng": -118.3418772
  },
  {
    "city": "Ontario, CA",
    "name": "Saving Zone",
    "location": "1333 E. 4th St, 91764",
    "hours": "9am - 8pm",
    "storeLink": "http://www.yelp.com/biz/saving-zone-ontario-2",
    "place_id": "ChIJodA6uxM0w4ARiFWjJ6-H-do",
    "lat": 34.0790529,
    "lng": -117.6278529
  },
  {
    "city": "South Gate, CA",
    "name": "Dollar Zone",
    "location": "8200 Long Beach Blvd. Ste F, 90280",
    "hours": "8am - 8pm",
    "storeLink": "http://www.yelp.com/biz/dollar-zone-long-beach",
    "place_id": "ChIJRV5qlEPJwoARJJtge5nSr2w",
    "lat": 33.9635482,
    "lng": -118.2246046
  },
  {
    "city": "Los Angeles, CA",
    "name": "First Bargain",
    "location": "Ennabe Center W 6th St, 90057",
    "hours": "n/a",
    "storeLink": "https://www.google.com/search?q=Ennabe+Center+W+6th+St%2C+90057",
    "place_id": "ChIJDX_Ila_HwoARSNMuVQ0HIuE",
    "lat": 34.0534129,
    "lng": -118.2633746
  }
]

var flags = [], l = locations.length, i;
export const output = []
for( i=0; i<l; i++) {
    if( flags[locations[i].city]) continue;
    flags[locations[i].city] = true;
    output.push({value: locations[i].city, label: locations[i].city});
}
// console.log(output);



export default {
    locations, output
}