import React from "react"
import Layout from "../components/layout"
import PageBanner from "../components/PageBanner"
import WhereToBuyContent from "../components/WhereToBuy/WhereToBuyContent"
import { Helmet } from "react-helmet"


const CoursesPage = () => (
  <>
    <Helmet>
      <title>Where to Buy | Maxie Paper</title>
      <meta charSet="utf-8" />
      <meta name="description" content=""/>
      <meta name="keywords" content="Maxie, Maxie Needs, Toilet Paper"/>
    </Helmet>
    <Layout>
      <PageBanner title="Where to Buy" description="Our vendors & locations"/>
      <WhereToBuyContent/>
    </Layout>
  </>
)

export default CoursesPage