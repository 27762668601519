import React from "react";
import styled from "styled-components";
import "../card.css";

const LocationCard = ({info}) => {
  return (
    <Container>
      <StoreLink
        href={info.storeLink}
        style={{ textDecoration: "none" }}
        target="_blank"
      >
        <InnerContainer>
          <City>{info.name}</City>
          <Detail>{info.city}</Detail>
          <Detail>{info.location}</Detail>
          <Detail>{info.hours}</Detail>
          <Detail>{info.place_id}</Detail>
          <Detail>{info.distance}</Detail>
          <StoreLink href={info.storeLink} target="_blank">
            Locate Us!
          </StoreLink>
        </InnerContainer>
      </StoreLink>
    </Container>
  );
};

export default LocationCard;


const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 720px;
  height: 290px;
  background-color: #eff9fe;
  border: 25px solid #eff9fe;
  border-right: 30px solid #eff9fe;
  border-radius: 25px;
  margin: 40px;

  :hover {
    background-color: #dbe8ef;
    border: 25px solid #dbe8ef;
    border-right: 30px solid #dbe8ef;
  }
`;
const StoreLink = styled.div`
  font-family: "Karla";
  font-style: semi-bold;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: blue;
  letter-spacing: 0px;
  margin-bottom: 10px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
  text-align: left;
`;

const City = styled.h2`
  font-family: "Karla";
  font-weight: 600;
  -webkit-text-stroke: 0.5px #4d4d4f;
  font-size: 33px;
  color: #4d4d4f;
  letter-spacing: 0.4px;
  margin-bottom: 10px;
`;

const Detail = styled.span`
  font-family: "Karla";
  font-style: semi-bold;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #4d4d4f;
  letter-spacing: 0px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
`;
